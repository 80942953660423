@import "mixins/banner";
@include bsBanner("");


// scss-docs-start import-stack
// Configuration
@import "functions";
@import "variables";
@import "variables-dark";
@import "maps";
@import "mixins";
@import "utilities";

// Layout & components
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "containers";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "nav";
@import "navbar";
@import "card";
@import "accordion";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "alert";
@import "progress";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "offcanvas";
@import "placeholders";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";
// scss-docs-end import-stack

.page-header
{
    text-shadow: 3px 3px #000;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    text-align: center;
    box-shadow: 0 4px 12px 0 #000000;
    margin-bottom:10px;
    background-position:center;
    padding-top: 60px;
    width: 100%;
    height: 250px;
}

.header-title
{
    text-shadow: 4px 4px #000;
    text-align: center;
    font-family: 'Arbutus', sans-serif;
}

.header-subtitle
{
    text-shadow: 3px 3px #000;
    text-align: center;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: normal;
    text-decoration: none;
}

#sitewide-message {
    background-color: #0e0e0e;
    border: none 0px;
    border-radius: 0px 0px 0px 0px;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: normal;
}

ul.hlist > li.hlist-item {
    display: inline;
}

li.hlist-item:after {
    content: "/";
    color: #202020;
}
li.hlist-item:last-child:after {
    content: "";
}

div.author {
    /*
    -webkit-box-shadow:0px 0px 10px 3px #2d2d2d ;
    -moz-box-shadow:0px 0px 10px 3px #2d2d2d ;
    box-shadow:0px 0px 10px 3px #2d2d2d ;
    border-top:3px ridge #292929;
    border-right:none;
    border-bottom:3px ridge #292929;
    border-left:none;
    */
    border-left: 12px solid #323D4F;
    border-bottom: 4px solid #323D4F;
    border-radius: 0px 0px 0px 30px;
    padding: 8px 8px 8px 8px;
    /* fuck this, it makes things look wayy more shit than they should on mobile.
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    */
}

img.author {
    border-radius: 100%;
    width: 100%;
    height: auto;
}

h5.author a.author {
    text-shadow: #e0e0e0 1px 1px 0;
    font-size: xx-large;
    color: #272727;
    font-family: 'Abril Fatface', serif;
    margin-bottom: 0px;
}
h6.author-role {
    text-shadow: #e0e0e0 1px 1px 0;
    font-size: large;
    color: #504f4f;
    font-family: 'Abril Fatface', serif;
    margin-top: -15px;
}

p.author-meta {
    text-shadow: #e0e0e0 1px 1px 0;
    font-size: 36px;
}

p.author-stats {
    font-size: smaller;
}

p.author-bio {
    color: #181818;
    text-shadow: #e0e0e0 1px 1px 0;
    font-family: 'Padauk', sans-serif;
}

$card-bg: #ffffff !default;
$card-fg: #202825 !default;

.card
{
    background-color:$card-bg;
    color:$card-fg;
}

.alert-dark
{
    background-color: $card-fg;
    color: $card-bg;
    border: none;
    border-radius: 0;
}

.post-img
{
    width:100%;
    height:auto;
    margin-bottom: 8px;
}

.img-shadow
{
    -webkit-box-shadow: 0px 10px 13px -7px #000000, 5px 5px 11px 5px rgba(0,0,0,0); 
    box-shadow: 0px 10px 13px -7px #000000, 5px 5px 11px 5px rgba(0,0,0,0);
}

img.circle {
    border-radius: 100%;
}

#image-viewer-img
{
    width:100%;
    height:auto;
}

/*
.nav-item {
    font-family: 'Caviar Dreams', sans-serif;
}
*/
.dropdown-item-title,.dropdown-item-title:hover {
    font-weight: bold;
    color: #ffffff;
    background-color: #343a40 !important;
}

span.triggered {
    color: #ff4848;
}

span.loltwitter {
    color: #1DA1F2;
}

video {
    position: relative !important;
    height: auto !important;
    max-height: 80vh !important;
    width: 100% !important;
}

body
{
    /*
    background-color: $body-bg;
    color: $body-fg;
    */
    font-family: 'Quicksand', sans-serif;
}

h1,h2,h3,h4,h5,h6
{
    font-family: 'Ostrich Sans Heavy', sans-serif;
}

.card-title
{
    font-family: 'Anonymous', sans-serif;
}

p
{
    /*color: $body-fg;*/
    font-family: 'Quicksand', sans-serif;
    margin-bottom: 15px;
    margin-top: 15px;
}

#content-wrapper
{
    width:80%;
    margin-left:10%;
    margin-right:10%;
    margin-bottom:5%;
}

.bg-dark
{
    /*background-color: #020202 !important;*/
}

pre,code
{
    background-color: transparent !important;
}

/*
blockquote
{
    margin: 10px 10px 10px 10px;
    padding: 5px 5px 5px 5px;
    border-left: 5px solid #000000;
    border-bottom: 1px solid #000000;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
*/
/*
.quote {
    font-family: 'Tenali Ramakrishna', sans-serif;
    font-size: large;
}
*/

.quote-author {
    font-size: small;
    font-weight: bold;
    text-align: right;
}
.quote-author::before
{
    content: '~';
    color: #999;
}

blockquote{
    display:block;
    /*background: #fff;*/
    padding: 15px 20px 15px 45px;
    margin: 0 0 20px;
    position: relative;
    
    /*Font*/
    font-family: 'Nanum Pen Script', serif;
    font-size: larger;
    /*line-height: 1.2;*/
    color: #666;
    text-align: justify;
    
    /*Borders - (Optional)*/
    border-left: 15px solid #3b3b3b;
    border-right: 2px solid #3b3b3b;
    
    /*Box Shadow - (Optional)*/
    -moz-box-shadow: 2px 2px 15px #ccc;
    -webkit-box-shadow: 2px 2px 15px #ccc;
    box-shadow: 2px 2px 15px #ccc;
  }
  p.quote
  {
    font-family: 'Libre Baskerville', serif;
    font-size: large;
  }
  p.quote-handwriting
  {
    font-family: 'Nanum Pen Script', serif;
    font-size: larger;
  }

  blockquote::before{
    content: "\201C"; /*Unicode for Left Double Quote*/
    
    /*Font*/
    font-family: Georgia, serif;
    font-size: 60px;
    font-weight: bold;
    color: #999;
    
    /*Positioning*/
    position: absolute;
    left: 10px;
    top:5px;
  }
  /*
  blockquote::after{
    content: "\201D";
    font-family: Georgia, serif;
    font-size: 60px;
    font-weight: bold;
    color: #999;
    position: absolute;
    right: 10px;
    top:5px;
  }
  */
  blockquote::after {
      content: "";
  }
  
  blockquote a{
    text-decoration: none;
    background: #eee;
    cursor: pointer;
    padding: 0 3px;
    color: #c76c0c;
  }
  
  blockquote a:hover{
   color: #666;
  }
  
  blockquote em{
    font-style: italic;
  }

  #image-viewer-img-alt
  {
    font-size: small;
    margin-left:5px;
    margin-right:5px;
  }

  ol {
    counter-reset: my-awesome-counter;
    list-style: none;
    padding-left: 40px;
  }
  ol li {
    margin: 0 0 0.5rem 0;
    counter-increment: my-awesome-counter;
    position: relative;
  }
  ol li::before {
    content: counter(my-awesome-counter);
    color: #fcd000;
    font-size: 1.5rem;
    font-weight: bold;
    position: absolute;
    --size: 32px;
    left: calc(-1 * var(--size) - 10px);
    line-height: var(--size);
    width: var(--size);
    height: var(--size);
    top: 0;
    transform: rotate(-10deg);
    background: black;
    border-radius: 50%;
    text-align: center;
    box-shadow: 1px 1px 0 #999;
  }
  .row {
      margin-top: 10px;
      margin-bottom: 10px;
  }

  .bordered-div
  {
    -webkit-box-shadow:0px 0px 5px 5px #2f2f2f ;
    -moz-box-shadow:0px 0px 5px 5px #2f2f2f ;
    box-shadow:0px 0px 5px 5px #2f2f2f ;

    border-radius:0px 10px 0px 10px;
    -moz-border-radius:0px 10px 0px 10px;
    -webkit-border-radius:0px 10px 0px 10px;

    padding: 7px 7px 7px 7px;
  }

  .bg-gentoo-purple {
    background-color: #54487A!important;
  }
  .bg-gentoo-purple-light {
    background-color: #61538D!important;
  }

  .bg-gentoo-purple-light2 {
    background-color: #6E56AF!important;
  }

  .bg-custom {
    background-color: #343a40!important;
  }

  .bg-sky-blue {
    background-color: #bfe3ff!important;
  }

  .bg-dark-blue {
    background-color: #454661!important;
  }

  .post-list-item {
    margin-bottom: 4px;
    border-radius: 32px 0px 32px 0px!important;
    padding: 8px 8px 8px 8px;
  }
  .post-list-title {
    color: #383838;
    font-family: 'Righteous', sans-serif;
    font-size: 125%;
    text-decoration: none;
    text-shadow: rgba(0,0,0,.2) 2px 6px 5px,rgba(255,255,255,.4) 0 -4px 30px;
  }

  .author-box {
    border: 1px solid #343A40;
    border-radius: 45px 0px 45px 0px;
    padding: 25px 25px 25px 25px;
    -webkit-box-shadow: 5px 5px 15px 3px rgba(0,0,0,0.5); 
    box-shadow: 5px 5px 15px 3px rgba(0,0,0,0.5);
    margin-bottom: 25px;
  }

  hr.pawn {
    width:65%;
    margin-left: 17.5%;
    margin-right: 17.5%;
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
    overflow: visible;
  }

  hr.pawn:after {
    content: "♙";
    display: inline-block;
    position: relative;
    top: -0.7em;
    font-size: 1.5em;
    padding: 0 0.25em;
    color: #8ad4ff;
  }

.featured-post-title {
  color: #ececec;
  font-family: 'Arbutus', sans-serif;
  font-size: 200%;
  text-shadow: 4px 4px 2px #000000;
  font-weight: normal;
  text-decoration: none;
  font-style: normal;
  font-variant: small-caps;
  text-transform: capitalize;
}

.featured-post-title a:hover{
  color: #525252;
  text-decoration:none;
}
 

.featured-post-text {
  color: #fff;
  font-family: 'Josefin Sans', sans-serif;
  font-size: 130%;
  font-weight: normal;
  text-decoration: none;
  text-shadow: 3px 3px 2px #000000;
}

.featured-post-text a:hover{
  color: #525252;
  text-decoration:none;
}